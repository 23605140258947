import React, { useState } from 'react';
import { Flex, Icon, Text } from '@aws-amplify/ui-react';
import { FormTemplate } from '../../custom-ui-components';
import { compareObjects, sleep } from '../../App';
import { LoadingSpinnerPage } from '../LoadingPage';
import { ErrorLoadingPage } from '../ErrorLoadingPage';
import { Navigate } from 'react-router-dom';
import {MdSettings} from 'react-icons/md';
import EmailCodePopUp from '../../custom-ui-components/EmailCodePopUp';


const ProfilePage = function(props) {
    const titleTextProps = props.titleTextProps;
    const standardTextProps = props.standardTextProps;
    //Get the current user to pass to subsequent functions
    const user = props.user;
    const userAttributes = user.attributes;
    const userID = user.username;
    const userEmail = userAttributes.email;
    let userAddressList = userAttributes["custom:addressList"];
    try {
        userAddressList = JSON.parse(userAddressList);
    } catch {}

    const APIFunctions = props.APIFunctions;

    const [emailCodePopUp, setEmailCodePopUp] = useState(null);
    const [formKey, setFormKey] = useState(0);
    const [modelsLoading, setModelsLoading] = useState(false);
    const [redirect, setRedirect] = useState(null);

    const userIDText = <Text 
        {...standardTextProps}
        fontSize={"12px"}
    >UserID: {userID}</Text> 

    const userEmailText = <Text 
        {...standardTextProps}
    >{userEmail}</Text> 

    let existingFirstNames = null;
    let existingLastName = null;
    let existingPhoneNumber = null;
    let existingGender = null;
    const existingEmail = props.user.attributes.email;


    const userIsTutor = props.userIsTutor;
    const userIsParent = props.userIsParent;
    const userIsStudent = props.userIsStudent;

    if (userIsTutor) {
        if (props.tutorModel == null) {
            return <LoadingSpinnerPage />
        }
        else if (props.tutorModel == "loading" && modelsLoading == false) {
            setModelsLoading(true);
        }
        else if (props.tutorModel == "error") {
            return <ErrorLoadingPage />
        }
        existingFirstNames = props.tutorModel.firstNames;
        existingLastName = props.tutorModel.lastName;
        existingPhoneNumber = props.tutorModel.phoneNumber;
        existingGender = props.tutorModel.gender;
    }
    else if (userIsParent) {
        if (props.parentModel == null) {
            return <LoadingSpinnerPage />
        }
        else if (props.parentModel == "loading" && modelsLoading == false) {
            setModelsLoading(true);
        }
        else if (props.parentModel == "error") {
            return <ErrorLoadingPage />
        }
        existingFirstNames = props.parentModel.firstNames;
        existingLastName = props.parentModel.lastName;
        existingPhoneNumber = props.parentModel.phoneNumber;
    }
    else if (userIsStudent) {
        if (props.studentModel == null) {
            return <LoadingSpinnerPage />
        }
        else if (props.studentModel == "loading" && modelsLoading == false) {
            setModelsLoading(true);
        }
        else if (props.studentModel == "error") {
            return <ErrorLoadingPage />
        }
        existingFirstNames = props.studentModel.firstNames;
        existingLastName = props.studentModel.lastName;
        existingPhoneNumber = props.studentModel.phoneNumber;
    }

    if (modelsLoading && props.tutorModel != "loading" && props.studentModel != "loading" && props.parentModel != "loading") {
        setModelsLoading(false);
        setFormKey(formKey + 1);
    }

    const submitForm = async function(answers) {
        try {
            const existingAnswers = {
                firstNames: existingFirstNames,
                lastName: existingLastName,
                phoneNumber: existingPhoneNumber,
                email: existingEmail,
            };
            if (userIsTutor) {
                existingAnswers.gender = existingGender;
            }
            if (answers.gender == "Other" && answers.pronouns != null && answers.pronouns != "") {
                answers.gender = answers.pronouns;
            }
            delete answers.pronouns;

            if (compareObjects(existingAnswers, answers)) {
                throw new Error("No changes made");
            }

            const existingModelAnswers = {
                firstNames: existingFirstNames,
                lastName: existingLastName,
                phoneNumber: existingPhoneNumber,
            };

            const modelAnswers = {
                firstNames: answers.firstNames,
                lastName: answers.lastName,
                phoneNumber: answers.phoneNumber,
            };

            const updateModels = !compareObjects(existingModelAnswers, modelAnswers);

            let tutorModelUpdates = {};
            const newUserAttributes = [];
            if (userIsTutor && updateModels) {
                tutorModelUpdates = {
                    firstNames: answers.firstNames,
                    lastName: answers.lastName,
                    phoneNumber: answers.phoneNumber
                };
            }
            if (existingAnswers.gender != answers.gender) {
                tutorModelUpdates.gender = answers.gender;
            }
            if (Object.entries(tutorModelUpdates).length > 0) {
                const newTutorModel = await APIFunctions.updateTutorModel(tutorModelUpdates);
                console.log(newTutorModel);
            }
            if (userIsParent && updateModels) {
                const newParentModel = await APIFunctions.updateParentModel({
                    firstNames: answers.firstNames,
                    lastName: answers.lastName,
                    phoneNumber: answers.phoneNumber
                });
                console.log(newParentModel);
            }
            if (userIsStudent && updateModels) {
                const newStudentModel = await APIFunctions.updateStudentModel({
                    firstNames: answers.firstNames,
                    lastName: answers.lastName,
                    phoneNumber: answers.phoneNumber
                });
                console.log(newStudentModel);
            }
            let newEmail = false;
            if (answers.email.toLowerCase() != existingEmail.toLowerCase()) {
                newEmail = true;
                newUserAttributes.push(
                    {
                        Name: "email", 
                        Value: answers.email.toLowerCase()
                    },
                    {
                        Name: "email_verified", 
                        Value: false
                    }
                );
            }

            if (newUserAttributes.length > 0) {
                await props.APIFunctions.updateUserAttributes(newUserAttributes);
            }

            if (newEmail == true) {
                setEmailCodePopUp(<EmailCodePopUp {...props} setPopUpVisibility={setEmailCodePopUp} newEmail={answers.email.toLowerCase()} />);
            }


        }
        catch (error) {
            if (error == "No changes made") {
                throw "No changes made";
            }
            throw {
                message: "Error updating settings",
                error: error
            };
        }
    }

    const questions = [];
    questions.push({
        id: "email", 
        label: "Email:", 
        type: "text", 
        required: true, 
        validations: [{type: "Email"}], 
        defaultValue: existingEmail, 
        placeholder: "Enter email"
    });
    if (userIsTutor || userIsParent || userIsStudent) {
        questions.push({
            id: "firstNames",
            label: "First Name(s):",
            type: "text",
            validations: [{type: "Name"}],
            required: true,
            defaultValue: existingFirstNames
        });

        questions.push({
            id: "lastName",
            label: "Surname:",
            type: "text",
            validations: [{type: "Name"}],
            required: true,
            defaultValue: existingLastName
        });

        questions.push({
            id: "phoneNumber", 
            label: "Contact Number:", 
            type: "text", 
            required: true, 
            validations: [{type: "UKMobPhone"}], 
            defaultValue: existingPhoneNumber, 
            placeholder: "Enter phone number"
        });
    }
    if (userIsTutor) {
        let genderDefaultValue = existingGender;
        let defaultPronounsValue = null;
        if (genderDefaultValue != "Male" && genderDefaultValue != "Female" && genderDefaultValue != "Non-Binary" && genderDefaultValue != "Other" && genderDefaultValue != "Prefer not to say") {
            genderDefaultValue = "Other";
            defaultPronounsValue = existingGender;
        }
        questions.push({
            id: "gender", 
            label: "Gender:",
            type: "radio",
            options: ["Male", "Female", "Non-Binary", "Other", "Prefer not to say"],
            required: true, 
            defaultValue: genderDefaultValue, 
        });
        
        questions.push({
            id: "pronouns", 
            label: "Pronouns:",
            type: "text",
            required: false,
            onlyShow: {id: "gender", answers: ["Other"]},
            defaultValue: defaultPronounsValue, 
        });
    }

    // questions.push({
    //     id: "addresses", 
    //     label: "Account Addresses:", 
    //     type: "existingornewaddress", 
    //     required: false, 
    //     validations: [{type: "ValidAddressList"}], 
    //     options: userAddressList, 
    //     defaultValue: null, 
    // });

    const formTitle = <Text {...titleTextProps}>Edit User Profile</Text>
    const userForm = <FormTemplate key={"form:" + formKey} {...props} questions={questions} submitAnswers={submitForm} okButtonFunction={"reset"} submitPopUpText={"Successfully updated user details"} />

    const openSettings = function () {
        setRedirect(<Navigate to={"/User/Settings"} />);
    }

    const iconSize = 50;
    const settingsButton = <Flex
        style={{cursor:"pointer"}} 
        onClick={openSettings}
        backgroundColor={"#f02b4c"} 
        borderRadius={iconSize/8 + "px"}
        width={iconSize + "px"}
        height={iconSize + "px"}
        justifyContent={"center"}
        alignItems={"center"}
    ><Icon
        key={"settings"}
        width={iconSize + "px"}
        height={iconSize + "px"}
        as={MdSettings}
        color={"#d9d9d9"}
    /></Flex>
    const settingsText = <Text {...standardTextProps}>Edit More Settings:</Text>

    return (
        <Flex direction={"column"}>
            <Flex direction={"column"} alignItems={"center"} gap={"1vh"} >
                <Flex direction={"column"} alignItems={"center"} justifyContent={"center"}>
                    <Flex alignItems={"center"} justifyContent={"center"}>
                        {settingsText}
                        {settingsButton}
                    </Flex>
                    {formTitle}
                </Flex>
                {userForm}
                {redirect}
                {userIDText}
            </Flex>
            <Flex direction={"column"} alignItems="center">
                {emailCodePopUp}
            </Flex>
        </Flex>
    );
}

//Return the correct page back up to the app and index files (with authenticator to force login)
export default ProfilePage;