import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { TextField, Text, Flex, Button, Badge, Grid, Icon, SelectField } from "@aws-amplify/ui-react";
import { useState, useEffect } from 'react';
import { validateField } from "./FormValidation";
import DropDownQuestionTemplate from "./DropDownQuestionTemplate";
import OptionsTemplate from "./OptionsTemplate";
import { compareObjects, examBoards, qualificationResults, subjects } from "../../App";
import FieldTemplate from "../FieldTemplate";

export const TutorSubjectsTemplate = function(props) {
	try {
		const labelTextProps = props.questionLabelProps || {};
		const errorTextProps = props.questionErrorProps || {};

		//Set question variables from passed properties
		const questionID = props.id;
		const questionLabel = props.label;
		const questionDescription = props.description;
		const required = props.required;
		const requiredAsterisk = props.requiredAsterisk;
		const defaultValue = props.defaultValue;
		const questionValidations = props.validations || [];
		const answersSuccessfullySubmitted = props.answersSuccessfullySubmitted;
		const qualificationAnswerValidations = [{"type":"ValidTutorQualification"}]

		let qualificationsValidationExists = false;
		for (let validation of questionValidations) {
			if (validation.type === "ValidTutorQualifications") {
				qualificationsValidationExists = true;
			}
		}
		if (qualificationsValidationExists === false) {
			questionValidations.push ({"type":"ValidTutorQualifications"});
		}

		const initialQualificationTypes = Object.keys(qualificationResults);
		//console.log(initialQualificationTypes)
		const subjectNames = [...subjects, "Other"];
		const UKExamBoards = [...examBoards];

		//Add an extra validation that will be in all day availability questions
		//This will ensure that the time range(s) given is always valid i.e. start time is before end time

		//Define a blank qualification for resetting to
		const blankQualification = {
			subject: null,
			qualificationType: null,
			result: null
		}

		//Set state variables used to store the current state and any errors
		const [qualificationAnswers, setQualificationAnswers] = useState([]);
		const [currentQualificationAnswer, setCurrentQualificationAnswer] = useState(blankQualification);
		const [resultOptions, setResultOptions] = useState([]);
		const [cancelButton, setCancelButton] = useState(null);
		const [addButton, setAddButton] = useState(null);
		const [addNewItemButton, setAddNewItemButton] = useState(null);
		const [badgeUIs, setBadgeUIs] = useState([]);
		const [errors, setErrors] = useState({questionHasError:false, questionError:"", currentQualificationError:true});
		const [qualificationTypes, setQualificationTypes] = useState(initialQualificationTypes);
		const [buttonOrSubject, setButtonOrSubject] = useState("button");
		const [subjectNameUI, setSubjectNameUI] = useState(null);
		const [qualificationTypeUI, setQualificationTypeUI] = useState(null);
		const [resultUI, setResultUI] = useState(null);
		const [examBoardUI, setExamBoardUI] = useState(null);

		//Set the default value of the qualification answers
		useEffect(() => {
			if (defaultValue == null) {
				setQualificationAnswers([]);
				return;
			}

			const validationResult = validateField(defaultValue, questionValidations);
			const questionHasError = validationResult.hasError;
			if (questionHasError == true) {
				setQualificationAnswers([]);
			}
			else {
				setQualificationAnswers(defaultValue);
			}
		}, [defaultValue]);

		const [forceUpdate, setForceUpdate] = useState(0);

		//Validate the qualification answers
		useEffect(() => {
			const validationResult = validateField(qualificationAnswers, questionValidations);
			const questionHasError = validationResult.hasError;
			const errorMessage = validationResult.errorMessage;

			if (forceUpdate > 0) {
				setErrors((prev) => {
					return {
						...prev,
						questionHasError: questionHasError, 
						questionError: errorMessage
					};
				});
			}
			
			props.handleChange(questionID, qualificationAnswers, questionHasError);
		}, [qualificationAnswers, forceUpdate]);

		//Validate the current qualification answer
		useEffect(() => {
			const currentValidationResult = validateField(currentQualificationAnswer, qualificationAnswerValidations);
			const currentQuestionHasError = currentValidationResult.hasError;

			if (forceUpdate > 0) {
				setErrors((prev) => {
					return {
						...prev,
						currentQualificationError: currentQuestionHasError
					};
				});
			}
		}, [currentQualificationAnswer, forceUpdate]);

		useEffect(() => {
			if (qualificationAnswers.length == 0) {
				return;
			}
			if (currentQualificationAnswer == null || currentQualificationAnswer.subject == null) {
				return;
			}
			const currentSubjectName = currentQualificationAnswer.subject;
			const newQualificationTypes = [];
			for (const qualificationType of initialQualificationTypes) {
				let qualificationTypeExists = false;
				for (const qualificationAnswer of qualificationAnswers) {
					const answerSubjectName = qualificationAnswer.subject;
					const answerQualificationType = qualificationAnswer.qualificationType;
					if (answerSubjectName == currentSubjectName && answerQualificationType == qualificationType) {
						qualificationTypeExists = true;
					}
				}
				if (qualificationTypeExists === false) {
					newQualificationTypes.push(qualificationType);
				}
			}
			setQualificationTypes(newQualificationTypes);
		}, [qualificationAnswers, currentQualificationAnswer]);


		let labelColour = labelTextProps.color || "#000000";
		if (props.validationError != null && props.validationError != null) {
			labelColour = props.validationErrorColour || "#ff0000";
		}
		//Get the text for the question label
		const labelText = <Text {...labelTextProps} color={labelColour}>
			{questionLabel}
		</Text>
		
		const descriptionText = <Text {...labelTextProps} fontSize={"14px"} fontStyle={"italic"}>
			{questionDescription}
		</Text>

		const errorText = <Text {...errorTextProps}>
			{errors.questionError}
		</Text>

		let renderedRequiredAsterisk = null;
		if (required == true) {
			renderedRequiredAsterisk = requiredAsterisk;
		}

		//Define the add new item button
		useEffect(() => {
			let newItemButtonText = "Add qualification";
			if (qualificationAnswers.length > 0) {
				newItemButtonText = "Add another qualification";
			}
			setAddNewItemButton(<Button
				//When clicked, all this does is change the mode to the adding new time range item mode
				disabled={answersSuccessfullySubmitted}
				onClick={() => {
					setButtonOrSubject("subject");
				}}
			>
				{newItemButtonText}
			</Button>);
		}, [qualificationAnswers]);

		//Define the cancel item button
		useEffect(() => {
			setCancelButton(<Button
				isDisabled={answersSuccessfullySubmitted}
				onClick={() => {
					setButtonOrSubject("button");
					setCurrentQualificationAnswer(blankQualification);
					setResultOptions([]);
					
					const validationResult = validateField(qualificationAnswers, questionValidations);

					setErrors((prev) => {
						return {
							...prev, 
							currentQualificationError: true,
							questionError: validationResult.errorMessage
						};
					});
				}}
			>
				Cancel
			</Button>);
		}, [answersSuccessfullySubmitted, qualificationAnswers]);

		//This function is called when the cross on an existing subject answer is pressed
		const removeQualification = function (qualification) {
			//Define a temperary new array for all the exising subject answers
			const newQualifications = [];
			//Add all the existing answers to the temperary array
			newQualifications.push(...qualificationAnswers);

			//Find time range answer to remove and remove it
			for (let index = 0; index < newQualifications.length; index = index + 1) {
				const existingQualification = newQualifications[index];
				if (compareObjects(existingQualification, qualification)) {
					newQualifications.splice(index, 1);
					break
				}
			}
			//Update the main time range answers array with the updated array (with the item removed)
			setQualificationAnswers(newQualifications);

			const validationResult = validateField(newQualifications, questionValidations);
			const questionHasError = validationResult.hasError;
			setErrors((prev) => {
				return {
					...prev,
					questionHasError: questionHasError,
					questionError: validationResult.errorMessage,
				};
			});
			props.handleChange(questionID, newQualifications, questionHasError);
		} 

		//Now create the actual array of badges with the correct properties so that to appear in the correct location
		//The row and column they're placed in depends on their index in the array
		useEffect(() => {
			const badgeArray = [];
			for (let badgeIndex = 0; badgeIndex < qualificationAnswers.length; badgeIndex = badgeIndex + 1) {
				const qualificationAnswer = qualificationAnswers[badgeIndex];
				const answerString = qualificationAnswer.qualificationType + ": " + qualificationAnswer.subject + " - Result: " + qualificationAnswer.result;

				//Create the badge object
				const answerBadge = <Badge
					style={{
						alignItems: "center",
						marginRight: 10,
						marginTop: 10,
						backgroundColor:"#0e418f",
						color:"#e6edfa"
					}}
					key={answerString}
					size="small"
					columnStart={(badgeIndex % 2) + 1}
					rowStart={Math.floor(badgeIndex / 2) + 1}
				>
					{answerString}
					<Icon
						//This is a cross icon that when clicked removes the time range answer
						style={{
							cursor: "pointer",
							paddingLeft: 3,
							width: 20,
							height: 20,
						}}
						viewBox={{ width: 20, height: 20 }}
						paths={[
							{
								d: "M10 10l5.09-5.09L10 10l5.09 5.09L10 10zm0 0L4.91 4.91 10 10l-5.09 5.09L10 10z",
								stroke: "white",
							},
						]}
						ariaLabel="button"
						onClick={() => {
							//When clicked, call the function to remove the item
							if (answersSuccessfullySubmitted=== false) {
								removeQualification(qualificationAnswer);
							}
						}}
					/>
				</Badge>
				//Add the created badge to the badge array
				badgeArray.push(answerBadge)
			}
			setBadgeUIs(badgeArray);
		}, [qualificationAnswers]);
		
		//Define the add button that submits a new answer to the subject answers
		useEffect(() => {
			let addButtonColour = "";
			let addButtonDisabled = true;
			if (errors.currentQualificationError !== true && answersSuccessfullySubmitted === false) {
				addButtonColour = "#82f768";
				addButtonDisabled = false;
			}

			setAddButton(<Button
				backgroundColor={addButtonColour}
				key={"AddButton"}
				isDisabled={addButtonDisabled}
				onClick={() => {
					//When clicked, run the necessary validations first
					//Rerun the basic validation
					const validationResultForAnswer = validateField(currentQualificationAnswer, qualificationAnswerValidations);
					if (validationResultForAnswer.hasError != true) {
						//Create a temperary array of answers that can be used by the validation function to ensure they are valid, even with the new item
						const newQualificationAnswers = [...qualificationAnswers, currentQualificationAnswer];
						//Validate that none of the subject answers (including the new one) are for the same subject
						const validationResultForAnswers = validateField(newQualificationAnswers, questionValidations);
						console.log(validationResultForAnswers);
						
						if (validationResultForAnswers.hasError != true) {
							//If the validation was successful, update the actual subject answers with the new answer added
							setQualificationAnswers(newQualificationAnswers);
							//Reset the state back to 'button' for a new time range to potentially be added
							setButtonOrSubject("button");
							//Reset the current subject answer to be blank for inputting a new subject
							setCurrentQualificationAnswer(blankQualification);
							setResultOptions([]);
							//Reset all errors
							setErrors((prev) => {
								return {
									...prev,
									currentQualificationError: true,
								};
							});
							props.handleChange(questionID, newQualificationAnswers, false);
						}
						else {
							//If the validation was unsuccessful, flag an error to tell the user
							setErrors((prev) => {
								return {
									...prev,
									currentQualificationError: true,
									questionError: validationResultForAnswers.errorMessage,
								};
							});
						}
					}
					else {
						//If the validation was unsuccessful, flag an error to tell the user
						setErrors((prev) => {
							return {
								...prev,
								currentQualificationError: true,
								questionError: validationResultForAnswer.errorMessage,
							};
						});
					}
				}}
			>
			Add
			</Button>);
		}, [currentQualificationAnswer, qualificationAnswers, answersSuccessfullySubmitted, errors.currentQualificationError]);
		
		//Set the result options based on the current qualification type
		useEffect(() => {
			const currentQualificationType = currentQualificationAnswer.qualificationType;
			const resultOptionsArray = qualificationResults[currentQualificationType];
			setResultOptions(resultOptionsArray);
		}, [currentQualificationAnswer.qualificationType]);

		//Subject Name UI
		useEffect(() => {
			setSubjectNameUI(<FieldTemplate
				id={"Subject"}
				fieldType={"dropdown"}
				label={"Subject name"}
				value={currentQualificationAnswer.subject}
				placeholder={"-Select a subject-"}
				disabled={answersSuccessfullySubmitted}
				options={subjectNames}

				//Runs when it detects a change in the input field
				onChange={(newValue) => {
					setCurrentQualificationAnswer((prev) => {
						return {...prev, subject: newValue};
					});
					setForceUpdate((prev) => {
						return prev + 1
					});
				}}
			/>);
		} , [currentQualificationAnswer.subject]);

		//Qualification Type UI
		useEffect(() => {
			setQualificationTypeUI(<FieldTemplate
				id={"QualificationType"}
				fieldType={"dropdown"} 
				label={"Qualification type:"}
				placeholder={"-Select a qualification-"}
				value={currentQualificationAnswer.qualificationType}
				disabled={answersSuccessfullySubmitted}
				options={qualificationTypes}

				//Runs when it detects a change in the input field
				onChange={(newValue) => {
					setCurrentQualificationAnswer((prev) => {
						const newQualificationAnswer = {...prev, qualificationType: newValue};
						if (prev.qualificationType != newValue) {
							newQualificationAnswer.result = null;
						}
						return newQualificationAnswer;
					});
					setForceUpdate((prev) => {
						return prev + 1
					});
				}}
			/>);
		} , [currentQualificationAnswer.qualificationType, qualificationTypes]);

		//Result UI
		useEffect(() => {
			if (currentQualificationAnswer.qualificationType == null || currentQualificationAnswer.qualificationType == "") {
				return;
			}
			setResultUI(<FieldTemplate
				id={"Result"}
				fieldType={"dropdown"} 
				label={"Result:"}
				placeholder={"-Select a result-"}
				value={currentQualificationAnswer.result}
				disabled={answersSuccessfullySubmitted}
				hidden={currentQualificationAnswer.qualificationType == null || currentQualificationAnswer.qualificationType == ""}
				options={resultOptions}

				//Runs when it detects a change in the input field
				onChange={(newValue) => {
					if (newValue == "") {
						newValue = null;
					}
					setCurrentQualificationAnswer((prev) => {
						return {...prev, result: newValue}
					});
					setForceUpdate((prev) => {
						return prev + 1
					});
				}}
			/>);
		} , [currentQualificationAnswer.result, resultOptions]);

		//Exam board UI
		useEffect(() => {
			setExamBoardUI(<FieldTemplate
				id={"ExamBoard"}
				fieldType={"dropdown"}
				label={"Exam Board:"}
				placeholder={"-Select an exam board-"}
				value={currentQualificationAnswer.examBoard}
				disabled={answersSuccessfullySubmitted}
				hidden={currentQualificationAnswer.qualificationType != "A-Level" && currentQualificationAnswer.qualificationType != "GCSE" }
				options={UKExamBoards}

				//Runs when it detects a change in the input field
				onChange={(newValue) => {
					setCurrentQualificationAnswer((prev) => {
						return {...prev, examBoard: newValue}
					});
					setForceUpdate((prev) => {
						return prev + 1
					});
				}}
			/>);
		} , [currentQualificationAnswer.qualificationType, currentQualificationAnswer.examBoard]);

			
		//Depending on the current state, return the correct components
		let formItem = null
		if (buttonOrSubject == "button") {
			//If the current state is 'button', return the add new item button
			formItem = addNewItemButton;
		}
		else if (buttonOrSubject == "subject") {
			//If the current state is 'time' (meaning a new time range is being inputted), return the time fields as well as errors and relevent buttons
			formItem = <Flex direction={"column"} gap={"2vh"}>
				<Flex direction={"column"} gap={"2vh"}>
					<Flex direction={"column"}>
						{subjectNameUI}
						{qualificationTypeUI}
						{resultUI}
						{examBoardUI}
					</Flex>
					<Flex justifyContent={"right"}>
						{cancelButton}
						{addButton}
					</Flex>
				</Flex>
			</Flex>
		}

		//Return text field component to calling function (Most likely a Form Template)
		return (
			<Flex direction="column" alignItems={"center"}>
				<Flex direction="column" marginBottom={"1vh"}>
					<Flex alignItems={"center"}>
						{renderedRequiredAsterisk}
						{labelText}
					</Flex>
					{descriptionText}
				</Flex>
				<Flex direction="column" alignItems={"center"}>
					{formItem}
					<Flex direction={"column"} alignItems={"center"} justifyContent={"center"}>
						{errorText}
					</Flex>
					<Flex direction={"column"} alignItems={"center"} gap={"0vh"}>
						{badgeUIs}
					</Flex>  
				</Flex>
			</Flex>
		);
	}
	catch (error) {
		throw "TutorSubjectsTemplate Error: " + error;
	}
}

export default TutorSubjectsTemplate;