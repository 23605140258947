import {useEffect, useState, React} from 'react';
import { LoadingSpinner, LoadingSpinnerPage } from '../LoadingPage';
import { Button, CheckboxField, Flex, Grid, Icon, ScrollView, Text } from '@aws-amplify/ui-react';
import {Link, Navigate, useLocation} from "react-router-dom";
import {MdArrowRight, MdArrowDropDown} from 'react-icons/md';
import { AppNavButton, DropDownQuestionTemplate, FormTemplate } from '../../custom-ui-components';
import MapTemplate from '../../custom-ui-components/EmbededMapTemplate';
import { ErrorLoadingPage } from '../ErrorLoadingPage';
import { modelsStringBuilder, sleep } from '../../App';
import PopUpTemplate from '../../custom-ui-components/form-components/PopUpTemplate';
import BackButton from '../../custom-ui-components/BackButton';
import CheckBoxesTemplate from '../../custom-ui-components/form-components/CheckBoxesTemplate';

const LessonRequest = function(props) {
    const titleTextProps = props.titleTextProps;
    const standardTextProps = props.standardTextProps;
    const requestTextProps = {...standardTextProps}
    requestTextProps.textAlign = "left";
    delete requestTextProps.maxWidth;
    //Get the current user to pass to subsequent functions
    const userSettings = props.settings || {};
    const width = props.width;
    const APIFunctions = props.APIFunctions;
    let distanceUnit = userSettings.distanceUnit || "imperial";
    if (distanceUnit != "metric" && distanceUnit != "imperial") {
        distanceUnit = "imperial";
    }
    const widthThreshold = 1000;
    let defaultShowDetails = true;
    if (props.width < widthThreshold) {
        defaultShowDetails = false
    }

    const lessonRequestDictionary = props.adminLessonRequestDictionary;
    const studentDictionary = props.studentDictionary;
    const parentDictionary = props.parentDictionary;
    const [lessonRequestProps, setLessonRequestProps] = useState(null);
    const [noLessonRequestModel, setNoLessonRequestModel] = useState(null);
    const [studentDetails, setStudentDetails] = useState(null);

    const [showDetails, setShowDetails] = useState(defaultShowDetails);
    const [possibleTutors, setPossibleTutors] = useState(null);
    const [notPossibleTutors, setNotPossibleTutors] = useState(null);
    const [selectedTutors, setSelectedTutors] = useState({});
    const [selectedTutor, setSelectedTutor] = useState(null);
    const [redirect, setRedirect] = useState(null);
    const [errorLoading, setErrorLoading] = useState(false);

    const [submitPopUp, setSubmitPopUp] = useState(false);
    const [preSubmitPopUp, setPreSubmitPopUp] = useState({show:false, text:""});
    const [answersSubmitted, setAnswersSubmitted] = useState(0);
    const [formSubmissionErrorPopUp, setFormSubmissionErrorPopUp] = useState({show:false, message:""});
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [currentAnswerIteration, setCurrentAnswerIteration] = useState(1);
    
    const [timeRanges, setTimeRanges] = useState([]);
    const [mapReload, setMapReload] = useState(0);
    const [anyQuestionErrors, setAnyQuestionErrors] = useState(true);

    //Set request props
    useEffect(() => {
        if (lessonRequestDictionary == null) {
            return;
        }

        const queryParams = new URLSearchParams(window.location.search);
        const requestID = queryParams.get("requestID");
        if (requestID == null) {
            console.log("No request ID");
            setNoLessonRequestModel(true);
            return;
        }
        const requestModel = lessonRequestDictionary[requestID];
        if (requestModel == null) {
            console.log("Request not found");
            setNoLessonRequestModel(true);
            return;
        }

        const requestType = requestModel["_typename"];
        requestModel.requestType = requestType;

        setLessonRequestProps(requestModel);
    }, [lessonRequestDictionary, window.location.search]);

    //Get student details
    useEffect(() => {
        if (lessonRequestProps == null || studentDictionary == null || parentDictionary == null) {
            return;
        }
        
        const studentIDs = lessonRequestProps.studentIDs;
        if (studentIDs == null) {
            console.log("No student IDs");
            setNoLessonRequestModel(true);
            return;
        }
        //Get student models
        const studentModels = [];
        for (const studentID of studentIDs) {
            const requestStudentModel = studentDictionary[studentID];
            if (requestStudentModel == null) {
                console.log("Student model not found");
                setNoLessonRequestModel(true);
                return;
            }
            studentModels.push(requestStudentModel);
        }
        
        //Get student details
        const newStudentDetails = {};
        newStudentDetails.numStudents = studentIDs.length;
        const studentDetailsTemplate = [
            {type: "key", key: "firstNames"},
            {type: "string", string: " "},
            {type: "key", key: "lastName"},
            {type: "string", string: " (", }, 
            {type: "key", key: "yearGroup"}, 
            {type: "string", string: " - ", conditions: [{parameter: "key", type: "exists", key: "schoolSet"}]}, 
            {type: "key", key: "schoolSet", conditions: [{parameter: "key", type: "exists", key: "schoolSet"}]},
            {type: "string", string: ")"}
        ];
        newStudentDetails.studentDetailsString = modelsStringBuilder(studentModels, studentDetailsTemplate, ", ", ", ");

        const namesTemplate = [
            {type: "key", key: "firstNames"},
            {type: "string", string: " "},
            {type: "key", key: "lastName"},
        ];
        newStudentDetails.studentNamesString = modelsStringBuilder(studentModels, namesTemplate, ", ", ", ");

        if (lessonRequestProps.parentID != null) {
            newStudentDetails.userType = "Parent";
            const parentID = lessonRequestProps.parentID;
            const parentModel = parentDictionary[parentID];
            if (parentModel == null) {
                console.log("Parent model not found");
                setNoLessonRequestModel(true);
                return;
            }
            newStudentDetails.parentName = parentModel.firstNames + " " + parentModel.lastName;
            newStudentDetails.email = parentModel.email;
            newStudentDetails.phoneNumber = parentModel.phoneNumber;
        }
        else {
            newStudentDetails.userType = "Student";
            const studentModel = studentModels[0];
            newStudentDetails.email = studentModel.email;
            newStudentDetails.phoneNumber = studentModel.phoneNumber;
        }

        setStudentDetails(newStudentDetails);
    }, [lessonRequestProps, studentDictionary, parentDictionary]);

    const getPossibleTutors = async function (completeSearch = false) {
        try {
            const foundPossibleTutorsReply = await APIFunctions.findTutorsForLesson(lessonRequestProps, completeSearch);
            const foundPossibleTutors = foundPossibleTutorsReply.possibleTutors;
            setPossibleTutors(foundPossibleTutors);
            if (completeSearch == true) {
                const foundNotPossibleTutors = foundPossibleTutorsReply.notPossibleTutors;
                setNotPossibleTutors(foundNotPossibleTutors);
            }
            
        }
        catch (error) {
            console.log(error);
            throw "Error getting possible tutors";
        }
    }
    
    useEffect(() => {
        if (width < widthThreshold) {
            setShowDetails(false);
        }
    }, []);

    //Get possible tutors on load
    useEffect(() => {
        if (lessonRequestProps == null) {
            return;
        }
        const getTutors = async function () {
            try {
                await getPossibleTutors(false);
            }
            catch {
                setErrorLoading(true);
            }
        }
        getTutors();
    }, [lessonRequestProps, APIFunctions]);


    if (errorLoading || possibleTutors == "error") {
        return <ErrorLoadingPage />
    }

    if (noLessonRequestModel) {
        return <Navigate to={"/Admin/LessonRequests"} />
    }
    if (lessonRequestProps == null || studentDetails == null) {
        return <LoadingSpinnerPage />
    }


    const studentNames = studentDetails.studentNamesString;
    const studentIDs = lessonRequestProps.studentIDs;
    const availabilityUI = [];
    const availabilityDays = Object.entries(lessonRequestProps.availability);
    const availabilityTitleText = <Text {...standardTextProps} key={"availabilityTitleText"}>
        Given availability:
    </Text>
    availabilityUI.push(availabilityTitleText);
    const dayOrder = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    const dayFlexes = [];
    for (const day of availabilityDays) {
        const dayName = day[0];
        let dayIndex = null;
        for (let validDayIndex = 0; validDayIndex < dayOrder.length; validDayIndex = validDayIndex + 1) {
          const validDay = dayOrder[validDayIndex];
          if (validDay == dayName) {
            dayIndex = validDayIndex;
          }
        }
        const dayAvailability = day[1];

        let dayAvailabilityText = "";
        const dayAvailabilityTextArray = [];
        for (let availabilityIndex = 0; availabilityIndex < dayAvailability.length; availabilityIndex = availabilityIndex + 1) {
            const availability = dayAvailability[availabilityIndex];
            const startTime = availability.startTime;
            const endTime = availability.endTime;
            
            let inserted = false;
            const startTimeHour = parseInt(startTime.split(":")[0]);
            const startTimeMinute = parseInt(startTime.split(":")[1]);
            const startTimeInt = (startTimeHour * 60) + startTimeMinute;
            const windowObject = {
                startTime: startTime,
                endTime: endTime,
                startTimeInt: startTimeInt
            };
            for (let windowIndex = 0; windowIndex < dayAvailabilityTextArray.length; windowIndex = windowIndex + 1) {
                const window = dayAvailabilityTextArray[windowIndex];
                if (startTimeInt < window.startTimeInt && inserted == false) {
                    dayAvailabilityTextArray.splice(windowIndex, 0, windowObject)
                    inserted = true;
                }
            }
            if (inserted == false) {
                dayAvailabilityTextArray.push(windowObject);
            }
        }
        const timesFlex = [];
        timesFlex.push(<Text {...standardTextProps} key={"DayName:" + dayName}>{dayName}:</Text>);
        for (const window of dayAvailabilityTextArray) {
            dayAvailabilityText = dayAvailabilityText + (window.startTime + " - " + window.endTime) + ",\n";
            const timeText = <Text {...standardTextProps} fontSize={"18px"} key={dayName + window.startTime}>{window.startTime} - {window.endTime}</Text>
            timesFlex.push(timeText);
        }
        let dayAvailabilityFlex = null;
        if (dayAvailabilityText != "") {
            dayAvailabilityFlex = <Flex 
                {...requestTextProps} 
                key={dayName + "Flex"} 
                direction={"column"} 
                textAlign={"start"} 
                alignItems={"flex-start"}
                gap={"0vh"}
            >
                {timesFlex}
            </Flex>
        }
        dayFlexes[dayIndex] = dayAvailabilityFlex;
    }
    for (const dayFlex of dayFlexes) {
        if (dayFlex != null) {
            availabilityUI.push(dayFlex);
        }
    }

    let UIs = [];
    const requestDate = new Date(lessonRequestProps.createdAt);
    let studentTitle = "Student:";
    if (studentIDs.length > 1) {
        studentTitle = "Students:";
    }
    UIs.push(
        <Text {...requestTextProps} key={"requestDate"}>
            Request date: {requestDate.toUTCString()}
        </Text>,
        <Text {...requestTextProps} key={"studentName"}>
            {studentTitle} {studentNames}
        </Text>
    );

    if (lessonRequestProps.parentID != null) {
        UIs.push(
            <Text {...requestTextProps} key={"parentName"}>
                Parent name: {studentDetails.parentName}
            </Text>
        );
    }

    const tutorLevels = [
        //"Only want an A-Level tutor",
        "Would much rather an A-Level tutor",
        "Would rather an A-Level tutor",
        "A-Level tutor would be slightly preferable",
        "I don't mind",
        "University tutor would be slightly preferable",
        "Would rather a university tutor",
        "Would much rather a university tutor",
        //"Only want a university tutor",
    ];

    UIs.push(
        <Text {...requestTextProps} key={"subject"}>
            Subject: {lessonRequestProps.subject}
        </Text>,
        <Text {...requestTextProps} key={"lessonType"}>
            Lesson type: {lessonRequestProps.lessonType}
        </Text>,
        <Text {...requestTextProps} key={"numSessions"}>
            Lessons per week: {lessonRequestProps.numSessions}
        </Text>,
        <Text {...requestTextProps} key={"sessionLength"}>
            Session length: {lessonRequestProps.sessionLength}
        </Text>
    );
    let requestedStartDate = lessonRequestProps.requestedStartDate;
    if (requestedStartDate == new Date().toDateString()) {
        requestedStartDate = "As soon as possible";
    }
    UIs.push(
        <Text {...requestTextProps} key={"requestedStartDate"}>
            Requested start date: {requestedStartDate}
        </Text>,
    );
    let schoolSetText = "";
    if (lessonRequestProps.schoolSet != null) {
        if (typeof(schoolSet) == "string") {
            schoolSetText = lessonRequestProps.schoolSet;
            UIs.push(
                <Text {...requestTextProps} key={"schoolSet"}>
                    Student ability: {schoolSetText}
                </Text>
            )
        }
    }
    UIs.push(
        <Flex direction={"column"} alignItems={"flex-start"} key={"availability"}>
            {availabilityUI}
        </Flex>,
        <Text {...requestTextProps} key={"tutorLevelValue"}>
            Requested tutor level: {tutorLevels[lessonRequestProps.tutorLevelValue - 1]}
        </Text>
    );

    if (lessonRequestProps.lessonType != "Online") {
        UIs.push(
            <Text {...requestTextProps} key={"address1stLine"}>
                Requested address: {lessonRequestProps.address.address1stLine}
            </Text>,
            <Text {...requestTextProps} key={"postcode"}>
                Postcode: {lessonRequestProps.address.postcode}
            </Text>
        );
        if (lessonRequestProps.address.geocode != null) {
            UIs.push(
                <Text {...requestTextProps} key={"latitude"}>
                    Latitude: {lessonRequestProps.address.geocode.latitude}
                </Text>,
                <Text {...requestTextProps} key={"longitude"}>
                    Longitude: {lessonRequestProps.address.geocode.longitude}
                </Text>
            );
        }
    }
    
    UIs.push(
        <Text {...requestTextProps} key={"email"}>
            {studentDetails.userType} email: {studentDetails.email}
        </Text>,
        <Text {...requestTextProps} key={"phoneNumber"}>
            {studentDetails.userType} phone number: {studentDetails.phoneNumber}
        </Text>
    );
    if (lessonRequestProps.notes != null) {
        UIs.push(
            <Text {...requestTextProps} key={"notes"}>
                Notes: {lessonRequestProps.notes}
            </Text>
        );
    }
    
    let arrowDirection = MdArrowDropDown;
    let showDetailsText = "Hide details";
    if (showDetails == false) {
        UIs = null;
        arrowDirection = MdArrowRight;
        showDetailsText = "Show details";
    }


    const showDetailsArrow = <Flex
        onClick={() => {
            setShowDetails(!showDetails);
        }}
        style={{cursor:"pointer"}}
        borderRadius={"10px"}
        backgroundColor={"#ffffff"}
        alignItems={"center"}
        width={"230px"}
        height={"35px"}
        position={"relative"}
    >
        <Text 
            {...standardTextProps}
            position={"absolute"}
            left={"10px"}
        >{showDetailsText}</Text>
        <Icon
            key={"editButton"}
            width={"30px"}
            height={"30px"}
            as={arrowDirection}
            right={"10px"}
            position={"absolute"}
        />
    </Flex>

    let requestWidth = "30vw";
    if (width < widthThreshold) {
        requestWidth = "90vw"
    }
    const requestFlex = <Flex 
        direction={"column"} 
        alignItems={"flex-start"} 
        width={requestWidth}
        columnStart={"1"}
        rowStart={"1"}
    >
        {showDetailsArrow}
        {UIs}
    </Flex>


    const handleChange = function (questionID, currentValue, questionHasError) {
        if (questionID == "SelectTutor") {
            if (currentValue == null || currentValue == "") {
                setSelectedTutor(null);
            }
            else {
                for (const possibleTutor of possibleTutors) {
                    const id = possibleTutor.id;
                    if (currentValue == id) {
                        setSelectedTutor(possibleTutor);
                        setMapReload(mapReload + 1);
                        break
                    }
                }
            }
        }
    }

    const preSubmitPopUpStages = async function() {
        setPreSubmitPopUp({
            show: true,
            text: "Verifying answers..."
        });
        const randomWait1 = Math.floor(Math.random() * 200) + 200;
        await sleep(randomWait1);
        setPreSubmitPopUp({
            show: true,
            text: "Submitting answers..."
        });
        const randomWait2 = Math.floor(Math.random() * 400) + 300;
        await sleep(randomWait2);
        setPreSubmitPopUp({
            show: true,
            text: "Waiting for server..."
        });
    }
    
    const submitAnswers = async function () {
        const tutorRequestAttributes = {};
        try {
            setAnswersSubmitted(currentAnswerIteration);
            preSubmitPopUpStages();
            setFormSubmitted(true);

            const tutorDetails = [];
            for (const tutorID in selectedTutors) {
                const tutor = {id: tutorID};
                tutor.firstNames = selectedTutors[tutorID].firstNames;
                tutor.lastName = selectedTutors[tutorID].lastName;
                tutor.matchingAvailability = selectedTutors[tutorID].matchingAvailability;
                tutorDetails.push(tutor);
            }

            tutorRequestAttributes.studentIDs = lessonRequestProps.studentIDs;
            tutorRequestAttributes.tutors = tutorDetails;
            tutorRequestAttributes.subject = lessonRequestProps.subject;
            tutorRequestAttributes.lessonRequestID = lessonRequestProps.id;

            console.log(tutorRequestAttributes);
            console.log("About to call tutor request API");
            const tutorRequest = await APIFunctions.createTutorRequest(tutorRequestAttributes);
            console.log("Got tutor request");
            setSubmitPopUp(true);
            setPreSubmitPopUp({show: false, text: ""});
            console.log(tutorRequest);
        }
        catch (error) {
            setCurrentAnswerIteration(currentAnswerIteration + 1);
            setFormSubmissionErrorPopUp({show:true, message: "Error choosing tutor for lesson request"});
            setPreSubmitPopUp({show: false, text: ""});
            setFormSubmitted(false);
            console.log(error);
            props.APIFunctions.logFormError(error, tutorRequestAttributes);
        }
    }

    const exitForm = function () {
        setRedirect(<Navigate to={"/Admin/LessonRequests"} />);
    }

    let tutorSelect = null;
    let timeOptionText = null;
    if (possibleTutors != null) {
        // const tutorOptions = [];
        const tutorSelectOptions = [];
        for (const possibleTutor of possibleTutors) {
            const name = possibleTutor.firstNames + " " + possibleTutor.lastName + " - " + possibleTutor.score;
            // const id = possibleTutor.id;
            // const newOption = {
            //     id: id,
            //     label: name
            // };
            // tutorOptions.push(newOption);
            tutorSelectOptions.push(<Flex key={possibleTutor.id} alignItems={"center"}>
                <CheckboxField
                    size={"medium"}
                    onChange={(e) => {
                        const currentValue = e.target.checked;
                        setSelectedTutors((prev) => {
                            const newSelectedTutors = {...prev};
                            if (currentValue == false) {
                                delete newSelectedTutors[possibleTutor.id];
                            }
                            else {
                                newSelectedTutors[possibleTutor.id] = possibleTutor;
                            }
                            return newSelectedTutors;
                        });
                    }}
                />
                <Text 
                    {...standardTextProps}
                    fontSize={"16px"}
                    onClick={() => {
                        setSelectedTutor(possibleTutor);
                        setMapReload(mapReload + 1);
                    }}
                    style={{cursor:"pointer", textAlign:"start"}}
                >
                    {name}
                </Text>
            </Flex>)
        }
        const tutorsSelected = Object.keys(selectedTutors);
        let tutorsSelectedText = "No tutors selected";
        if (tutorsSelected.length == 1) {
            tutorsSelectedText = "1 tutor selected";
        }
        else {
            tutorsSelectedText = tutorsSelected.length + " tutors selected";
        }
        tutorSelect = <Flex 
            direction={"column"}
            gap={"0px"}
        >
            <Flex 
                direction={"column"}
                gap={"0px"}
                paddingLeft={"10px"}
                paddingRight={"10px"}
                alignItems={"flex-start"}
                maxHeight={"20vh"}
                borderRadius={"10px"}
                style={{
                    overflowY:"auto",
                    borderStyle: "solid",
                    borderColor: "#de1a16"
                }}
                className="CustomScrollbar"
            >
                {tutorSelectOptions}
            </Flex>
            <Text {...standardTextProps} fontSize={"16px"}>{tutorsSelectedText}</Text>
        </Flex>
        // tutorSelect = <DropDownQuestionTemplate 
        //     handleChange = {handleChange}
        //     key = {"SelectTutor"}
        //     id = {"SelectTutor"}
        //     label = {"Select a possible tutor"}
        //     options = {tutorOptions}
        // />
        if (possibleTutors.length == 0) {
            tutorSelect = <Text {...standardTextProps}>No tutors found</Text>
        }
    }
    else {
        tutorSelect = <LoadingSpinner />
    }

    console.log(selectedTutors);

    let possibleTutorFlex = null;
    let createLessonForm = null;
    if (selectedTutor != null) {
        const distanceKm = selectedTutor.distance;
        let tutorDistance = distanceKm + " Km";
        if (lessonRequestProps.lessonType != "Online" && distanceUnit == "imperial") {
            const distanceMiles = Math.round((distanceKm * 0.621371) * 100) / 100;
            tutorDistance = distanceMiles + " miles";
        }
        let numSessionsWantedColour = standardTextProps.color;
        if (selectedTutor.numExistingLessons >= selectedTutor.numSessionsWanted) {
            if (selectedTutor.numExistingLessons == selectedTutor.numSessionsWanted) {
                numSessionsWantedColour = "#bbff00";
            }
            else {
                numSessionsWantedColour = "#bb0000";
            }
        }
        let score = selectedTutor.score;
        if (score < 1) {
            score = 1;
        }
        let scoreRed = 0;
        if (score <= 50) {
            scoreRed = 255;
        }
        else {
            const redDecimal = (score - 50) / 50
            scoreRed = Math.floor(255 - (redDecimal * 255));
        }
        let redHex = scoreRed.toString(16);
        if (redHex.length == 1) {
            redHex = "0" + redHex;
        }
        let scoreGreen = 0;
        if (score >= 50) {
            scoreGreen = 255;
        }
        else {
            const greenDecimal = (score) / 50
            scoreGreen = Math.floor(greenDecimal * 255);
        }
        let greenHex = scoreGreen.toString(16);
        if (greenHex.length == 1) {
            greenHex = "0" + greenHex;
        }
        const scoreColour = "#" + redHex + greenHex + "00";

        let distanceText = null;
        let distanceMultiplierText = null;
        if (lessonRequestProps.lessonType != "Online") {
            distanceText = <Text {...standardTextProps}> Direct distance: {tutorDistance}</Text>
            distanceMultiplierText = <Text {...standardTextProps}>Distance multiplier: {selectedTutor.distanceMultiplier}</Text>
        }
        const tutorLevelMultiplierText = <Text {...standardTextProps}>Tutor level multiplier: {selectedTutor.tutorLevelMultiplier}</Text>

        let genderText = null;
        if (selectedTutor.gender != null) {
            genderText = <Text {...standardTextProps}>Gender: {selectedTutor.gender}</Text>
        }
        let qualificationsText = "";
        for (const [qualificationType, qualificationDetails] of Object.entries(selectedTutor.qualifications)) {
            qualificationsText = qualificationsText + qualificationType + ": " + qualificationDetails.result + ", ";
        }
        qualificationsText = qualificationsText.slice(0, -2);
        possibleTutorFlex = <Flex direction={"column"}>
            <Text {...standardTextProps}>{selectedTutor.firstNames + " " + selectedTutor.lastName}</Text>
            <Text {...standardTextProps} color={scoreColour}>Score: {selectedTutor.score}</Text>
            <Text {...standardTextProps}>{lessonRequestProps.subject} qualifications: {qualificationsText} {selectedTutor.grade}</Text>
            <Text {...standardTextProps}>{selectedTutor.level}</Text>
            {genderText}
            {distanceText}
            {distanceMultiplierText}
            {tutorLevelMultiplierText}
            <Text {...standardTextProps} color={numSessionsWantedColour}>Weekly lessons wanted: {selectedTutor.numSessionsWanted}</Text>
            <Text {...standardTextProps} color={numSessionsWantedColour}>Weekly lessons already: {selectedTutor.numExistingLessons}</Text>
        </Flex>
    }
    
    const titleText = <Text {...titleTextProps}>View lesson request</Text>

    let submitButtonDisabled = false;
    let submitButtonColour = "#82f768"
    if (formSubmitted || Object.keys(selectedTutors).length < 1) {
        submitButtonDisabled = true;
        submitButtonColour = null;
    }

    const submitButton = <AppNavButton text={"Submit"} width={width} onClickFunction={submitAnswers} backgroundColour={submitButtonColour} disabled={submitButtonDisabled} />
    const backButton = <BackButton {...props} defaultBack={"/Admin/LessonRequests"} />

    const getInvalidTutors = async function () {
        setNotPossibleTutors("loading");
        try {
            const foundTutorsReply = await APIFunctions.findTutorsForLesson(lessonRequestProps, true);
            const foundNotPossibleTutors = foundTutorsReply.notPossibleTutors;
            setNotPossibleTutors(foundNotPossibleTutors);
        }
        catch (error) {
            console.log(error);
            throw "Error getting possible tutors";
        }
    };

    const getInvalidTutorsButton = <AppNavButton text={"Get invalid tutors"} width={width} onClickFunction={getInvalidTutors} /> 

    const invalidTutorsUIs = [];
    if (notPossibleTutors != null) {
        if (notPossibleTutors != "loading") {
            const invalidTutors = Object.values(notPossibleTutors);
            for (const tutor of invalidTutors) {
                const reasonsUIs = [];
                for (const reason of tutor.invalidReasons) {
                    reasonsUIs.push(
                        <Text key={tutor.id + reason} {...standardTextProps} fontSize={"12px"}>{reason}</Text>
                    );
                }

                const invalidTutorFlex = <Flex
                    key={tutor.id + "invalid"}
                    direction={"column"}
                    alignItems={"center"}
                    gap={"0vh"}
                    margin={"10px"}
                >
                    <Text {...standardTextProps} fontSize={"15px"}>
                        {tutor.firstNames + " " + tutor.lastName + ":"}
                    </Text>
                    {reasonsUIs}
                </Flex>
                invalidTutorsUIs.push(invalidTutorFlex);
            }
        }
    }

    let invalidTutorsUI = <ScrollView 
        maxHeight="30vh"
        style={{
            borderStyle: "solid",
            borderColor: "#2e66ff",
            borderRadius: "10px"
        }}
    >
        {invalidTutorsUIs}
    </ScrollView>

    if (notPossibleTutors == "loading") {
        invalidTutorsUI = <LoadingSpinner key={"loadingSpinner"}/>;
    }
    else if (notPossibleTutors == null) {
        invalidTutorsUI = null;
    }


    let direction = "row";
    if (width < 1000) {
        direction = "column";
    }

    let embeddedMap = null;
    let embeddedMapDefault = null;
    let mapWidth = 30*width/100
    if (width < 1000) {
        mapWidth = 80*width/100
    }
    if (lessonRequestProps.lessonType != "Online" && selectedTutor != null) {
        embeddedMap = <MapTemplate
            {...props}
            type={"directions"} 
            origin={selectedTutor.address} 
            destination={lessonRequestProps.address} 
            width={mapWidth}
            height={"400px"}
            distanceUnit={userSettings.distanceUnit}
            mode={"walking"}
            directDistance={selectedTutor.distance}
            key={"Map" + mapReload}
        />
        embeddedMapDefault = null;
    }
    else if (lessonRequestProps.lessonType != "Online") {
        embeddedMapDefault = <MapTemplate
            {...props}
            type={"palce"} 
            origin={lessonRequestProps.address} 
            width={mapWidth}
            height={"400px"}
        />
    }
    
    let possibleTutorRow = "1";
    let possibleTutorColumn = "2";
    let mapRow = "1";
    let mapColumn = "3";
    let templateColumns = "1fr 1fr 1fr";
    let mapXOffeset = "-10px";
    if (lessonRequestProps.lessonType == "Online") {
        //possibleTutorColumn = "3";
    }
    if (width < widthThreshold) {
        possibleTutorRow = "2";
        possibleTutorColumn = "1";
        mapRow = "3";
        mapColumn = "1";
        templateColumns = "1fr";
        mapXOffeset = "5vw";
    }

    const preSubmitPopUpText = preSubmitPopUp.text;
    const submitPopUpText = "Successfully sent tutor request";

    const invalidOKFunction = function () {
        setFormSubmitted(false);
    }

    const createLessonButton = <Link to={"/Admin/CreateNewLesson"} state={{...lessonRequestProps}} style={{textDecoration: 'none'}}><Button>Manually create lesson from request</Button></Link>

    return (
        <Flex direction={"column"} gap={"4vh"} alignItems={"center"}>
            {redirect}
            <Flex direction={"column"} gap={"4vh"} alignItems={"center"}>
                {titleText}
                <Flex gap={"4vw"}>
                    {backButton}
                    {createLessonButton}
                </Flex>
            </Flex>
            <Grid rowGap={"2vh"} columnGap={"1vw"} position={"relative"} templateColumns={templateColumns}>
                {requestFlex}
                <Flex 
                    direction={"column"} 
                    alignItems={"center"}
                    columnStart={possibleTutorColumn}
                    rowStart={possibleTutorRow}
                >
                    {tutorSelect}
                    {submitButton}
                    {possibleTutorFlex}
                    <Flex direction={"column"}>
                        {timeOptionText}
                    </Flex>
                    {getInvalidTutorsButton}
                    {invalidTutorsUI}
                </Flex>
                <Flex
                    rowStart={mapRow}
                    columnStart={mapColumn}
                    width={mapWidth}
                    direction={"column"}
                    right={mapXOffeset}
                    position={"absolute"}
                    //alignItems={"center"}
                    //justifyContent={"center"}
                    
                >
                    {embeddedMap}
                    {embeddedMapDefault}
                </Flex>
                <Flex
                    rowStart={mapRow}
                    columnStart={mapColumn}
                    width={mapWidth}
                    height={"500px"}
                ></Flex>
            </Grid>
            {(preSubmitPopUp.show && (answersSubmitted == currentAnswerIteration)) && <PopUpTemplate key={"preSubmit" + preSubmitPopUp.text} text={preSubmitPopUpText} setPopUpVisibility={setPreSubmitPopUp} showXButton={false} />}
            {submitPopUp && <PopUpTemplate text={submitPopUpText} setPopUpVisibility={setSubmitPopUp} okButtonFunction={exitForm} showXButton={false} />}
            {formSubmissionErrorPopUp.show && <PopUpTemplate text={formSubmissionErrorPopUp.message} setPopUpVisibility={setFormSubmissionErrorPopUp} okButtonFunction={invalidOKFunction} showXButton={false} reloadOnDismiss={true} />}
        </Flex>
    );
}

//Return the correct page back up to the app and index files (with authenticator to force login)
export default LessonRequest;