import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { Button, Flex, Grid, Text, TextField } from "@aws-amplify/ui-react";
import { LoadingSpinner, LoadingSpinnerPage } from "../LoadingPage";
import { AppNavButton } from "../../custom-ui-components";
import { loadStripe } from "@stripe/stripe-js";
import { calculateLessonCost, getStripePriceID, isValidPrice, priceValuetoString } from "../../App";
import BuyCreditTemplate from "../../custom-ui-components/BuyCreditTemplate";


const LessonCredits = function(props) {
	const height = props.height;
	const titleTextProps = props.titleTextProps;
	const standardTextProps = props.standardTextProps;
	const userIsParent = props.userIsParent;
	const userIsStudent = props.userIsStudent;
	const parentModel = props.parentModel;
	const studentModel = props.studentModel;
	const studentLessons = props.studentLessons;
	const parentLessons = props.parentLessons;
	const environment = props.environment;

	const [redirect, setRedirect] = useState(null);
	const [basket, setBasket] = useState({total: 0});
	const [basketCredit, setBasketCredit] = useState("");
	const [creditInput, setCreditInput] = useState(null);
	const [checkoutButton, setCheckoutButton] = useState(null);
	const [lessonText, setLessonText] = useState(null);
	const [currentLessonCredit, setCurrentLessonCredit] = useState(null);

	//Lesson info
	useEffect(() => {
		if (userIsParent == null || userIsStudent == null || currentLessonCredit == null) {
			return;
		}
		if ((userIsStudent && studentLessons == null) || (userIsParent && parentLessons == null)) {
			setLessonText(<LoadingSpinner {...props} />);
			return;
		}
		
		//Ensure no lessons are repeated e.g. if the user is somehow both the student and the parent
		const lessonsObject = {};
		if (userIsStudent) {
			for (const lesson of studentLessons) {
				lessonsObject[lesson.id] = lesson;
			}
		}
		if (userIsParent) {
			for (const lesson of parentLessons) {
				lessonsObject[lesson.id] = lesson;
			}
		}

		const lessonArray = Object.values(lessonsObject); 

		let currentLessonCost = 0;
		let currentLessonCostText = <Text {...standardTextProps}>Error calculating current lesson cost</Text>;
		let prepaidLessonsText = null;
		for (const lesson of lessonArray) {
			const startTime = lesson.startTime;
			const endTime = lesson.endTime;
			const tutorLevel = lesson.tutorLevel;

			const lessonCost = calculateLessonCost(tutorLevel, startTime, endTime, lesson.lessonType);
			currentLessonCost = currentLessonCost + lessonCost;
		}

		if (currentLessonCost != "error") {
			const currentLessonCostString = priceValuetoString(currentLessonCost);
			let numPrepaidLessonWeeks = Math.floor(currentLessonCredit/currentLessonCost);
			if (numPrepaidLessonWeeks < 0) {
				numPrepaidLessonWeeks = 0;
			}

			currentLessonCostText = <Text {...standardTextProps}>Your current total weekly lesson cost is: {currentLessonCostString}</Text>
			prepaidLessonsText = <Text {...standardTextProps}>Your current balance will pay for your lessons for {numPrepaidLessonWeeks} weeks</Text>
			if (currentLessonCost == 0) {
				prepaidLessonsText = null;
			}
		}

		setLessonText(
			<Flex direction={"column"} gap={"1vh"} alignItems={"center"}>
				{currentLessonCostText}
				{prepaidLessonsText}
			</Flex>
		);

	}, [studentLessons, parentLessons, userIsStudent, userIsParent, currentLessonCredit]);

	//Current lesson credit
	useEffect(() => {
		if (userIsParent == null || userIsStudent == null) {
			return;
		}
		let newCurrentCredit = 0;
		if (userIsParent) {
			if (parentModel == null) {
				return;
			}
			newCurrentCredit = newCurrentCredit + parentModel.lessonCredits || 0;
		}
		if (userIsStudent) {
			if (studentModel == null) {
				return;
			}
			newCurrentCredit = newCurrentCredit + studentModel.lessonCredits || 0;
		}
		setCurrentLessonCredit(newCurrentCredit);
	}, [parentModel, studentModel, userIsParent, userIsStudent]);

	//Credit input
	useEffect(() => {
		const handleCreditInput = function (event) {
			let newValue = event.target.value
			newValue = newValue.split("£").join("");
			const validValue = isValidPrice(newValue, false);
			if (validValue == true) {
				setBasketCredit(newValue);
			}
			else if (newValue == "") {
				setBasketCredit("");
			}
			else {
				console.log("Invalid credit value: " + validValue);
			}
		}

		setCreditInput(
			<Flex direction={"column"} gap={"0vh"} alignItems={"center"}>
				<Text {...standardTextProps}>Purchase amount:</Text>
				<TextField 
					value={"£" + basketCredit}
					onChange={handleCreditInput} 
				/>
			</Flex>
		);
	}, [basketCredit]);

	//Update basket
	useEffect(() => {
		if (basketCredit == null) {
			return;
		}
		const creditValue = parseFloat(basketCredit, 10);
		if (typeof(creditValue) != "number") {
			return;
		}
		const creditDenominations = [1000, 500, 200, 100, 50, 20, 10, 5, 2, 1, 0.5, 0.2, 0.1, 0.05, 0.02, 0.01];
		const denominationsBought = {};
		let totalRemaining = Math.round(creditValue * 100);
		for (const creditDenomination of creditDenominations) {
			const numBought = Math.floor(totalRemaining / (creditDenomination * 100));
			if (numBought > 0) {
				const stripePriceID = getStripePriceID(creditDenomination, environment);
				denominationsBought[creditDenomination] = {
					stripePriceID: stripePriceID,
					quantity: numBought
				}
			}
			totalRemaining = totalRemaining - (numBought * creditDenomination * 100);
		}
		//console.log(denominationsBought);
		setBasket(denominationsBought);
	}, [basketCredit, environment]);

	//Checkout button
	useEffect(() => {
		const buyCredits = async function () {
			if (environment == null) {
				console.log("No backend environment found");
				return;
			}
			console.log("Buy credits");
			let stripeKey = "pk_live_51N1smKDLqgKSpJ1sFLtEzOlBIloEZw24PvC5SpSUYqDwjF7U0qbPpUFwxzPv2h7bzxfPuf1LxTj44xzV7jUA5jne00JeGUJVMY";
			if (environment == "testing") {
				stripeKey = "pk_test_51N1smKDLqgKSpJ1sy2e8zbmKjT2Sl4SwUsCwkLTVlRDlF46ngWYtvTne7HenqjlUb0DyvQ125vHqQmyTITBOKAEw00csBNu8a7";
			}
			console.log(environment);
			const stripe = await loadStripe(stripeKey);
			const successUrl = props.origin + "/LessonCredits";
			const cancelUrl = props.origin + "/LessonCredits";
	
			const lineItems = [];
	
			const basketArray = Object.entries(basket);
			for (const basketItem of basketArray) {
				if (basketItem[0] != "total") {
					if (basketItem[1].quantity > 0) {
						lineItems.push({
							price: basketItem[1].stripePriceID,
							quantity: basketItem[1].quantity,
						});
					}
				}
			}
	
			await stripe.redirectToCheckout({
				lineItems: lineItems,
				mode: "payment",
				successUrl: successUrl,
				cancelUrl: cancelUrl,
				clientReferenceId: props.user.username,
				customerEmail: props.user.attributes.email,
			});
		};
		let totalItems = 0;
		let totalCost = 0;
		for (const [itemValue, basketItem] of Object.entries(basket)) {
			if (basketItem.quantity > 0) {
				totalItems = totalItems + basketItem.quantity;
				totalCost = totalCost + (itemValue * basketItem.quantity);
			}
		}
		let disabledReason = null;
		let maxCreditPurchase = 99999999;
		if (totalItems == 0) {
			disabledReason = "";
		}
		else if (totalCost > maxCreditPurchase) {
			disabledReason = "Can't buy more than £" + maxCreditPurchase + " worth of credits at once";
		}
		else if (totalCost < 5) {
			disabledReason = "Minimum spend £5";
		}

		let disabledText = null;
		if (disabledReason != null && disabledReason != "") {
			disabledText = <Text 
				fontSize={"15px"} 
				color={"#ff0000"}
				position={"absolute"}
				top={"-25px"}
			>
				{disabledReason}
			</Text>
		}
		setCheckoutButton(
			<Flex direction={"column"} alignItems={"center"} position={"relative"} width={"100%"}>
				<AppNavButton text={"Checkout"} onClickFunction={buyCredits} width={props.width} disabled={disabledReason != null} />
				{disabledText}
			</Flex>
		);
	}, [basket, environment, props.width]);
	
	if (userIsParent != null && !userIsParent && userIsStudent != null && !userIsStudent) {
		return <Navigate to="/" /> 
	}

	if (currentLessonCredit == null) {
		return <LoadingSpinnerPage {...props} />
	}

	const titleText = <Text {...titleTextProps}>Lesson Credit</Text>

	const lessonCreditString = props.lessonCreditString;
	const currentCreditsText = <Text {...standardTextProps}>You currently have {lessonCreditString} credit</Text>

	const navigateToTransactions = function () {
		setRedirect(<Navigate to={"/Transactions"} />);
	}
	const transactionsButton = <AppNavButton text={"View previous transactions"} onClickFunction={navigateToTransactions}/> 

	
	const descriptionText1 = <Text {...standardTextProps}>Lesson credit is used to pay for lessons and is deducted on the day of each session.</Text>
	const descriptionText2 = <Text {...standardTextProps}>You can purchase more lesson credit at any point below:</Text>


	const descriptionFlex = <Flex direction={"column"} gap={"0.5vh"} alignItems={"center"}>
		<Flex>
			{transactionsButton}
		</Flex>
		{descriptionText1}
		{lessonText}
		{descriptionText2}
	</Flex>

	let buyCreditsText = "Buy more lesson credits";
	if (currentLessonCredit == 0) {
		buyCreditsText = "Buy lesson credits";
	}

	let tableFontProps = {...standardTextProps};
	if (props.width < 520) {
		tableFontProps.fontSize = "17px";
	} 
	tableFontProps.style = {cursor: "pointer"}

	// const pricingBackgroundColour = "#fff0a6";

	// const creditsPricing = <Flex direction={"column"} gap={"1vh"} alignItems={"center"}>
	// 	<Text {...standardTextProps} marginBottom={"1vh"}>Basket Total: {priceValuetoString(basket.total)}</Text>
	// 	<BuyCreditTemplate {...props} creditValue={5} environment={environment} basket={basket} setBasket={setBasket} key={"£5"}/>
	// 	<BuyCreditTemplate {...props} creditValue={7.5} environment={environment} basket={basket} setBasket={setBasket} key={"£7.50"}/>
	// 	<BuyCreditTemplate {...props} creditValue={10} environment={environment} basket={basket} setBasket={setBasket} key={"£10"}/>
	// 	<BuyCreditTemplate {...props} creditValue={20} environment={environment} basket={basket} setBasket={setBasket} key={"£20"}/>
	// 	<BuyCreditTemplate {...props} creditValue={50} environment={environment} basket={basket} setBasket={setBasket} key={"£50"}/>
	// 	<BuyCreditTemplate {...props} creditValue={100} environment={environment} basket={basket} setBasket={setBasket} key={"£100"}/>
	// 	<Flex width={"fit-content"} justifyContent={"center"} alignSelf={"center"} marginTop={"1vh"}>
	// 		<AppNavButton text={"Checkout"} onClickFunction={buyCredits} width={props.width} />
	// 	</Flex>
	// </Flex>

	const tableColour = "#000000";
	tableFontProps.margin = "1vh";
	const creditsTableProps = {...tableFontProps};
	creditsTableProps.fontSize = "1.5vh";
	creditsTableProps.fontStyle = "italic";
	creditsTableProps.alignItems = "center";
	creditsTableProps.color = "#3c00f2";
	creditsTableProps.style = null;
	const tableHeaderProps = {...tableFontProps}
	tableHeaderProps.fontSize = "1.6vh";
	tableHeaderProps.style = null;

	const lessonCreditsTable = <Grid  border={"solid"} borderRadius={"10px"}>
		<Text {...tableHeaderProps} column={1} row={1}>Lesson Pricing</Text>

		<Text {...tableHeaderProps} column={2} row={1}>45 Minutes</Text>
		<Text {...tableHeaderProps} column={3} row={1}>1 Hour</Text>
		<Text {...tableHeaderProps} column={4} row={1}>1 Hour 30 Minutes</Text>
		<Text {...tableHeaderProps} column={5} row={1}>2 Hours</Text>

		<Text {...tableHeaderProps} column={1} row={2}>A Level Tutor (In-Person/Online)</Text>
		<Text {...tableHeaderProps} column={1} row={3}>University Tutor (In-Person)</Text>
		<Text {...tableHeaderProps} column={1} row={4}>University Tutor (Online)</Text>

		<Text {...creditsTableProps} column={2} row={2}>{calculateLessonCost("A-Level", null, null, "In-Person", true, 45)}</Text>
		<Text {...creditsTableProps} column={3} row={2}>{calculateLessonCost("A-Level", null, null, "In-Person", true, 60)}</Text>
		<Text {...creditsTableProps} column={4} row={2}>{calculateLessonCost("A-Level", null, null, "In-Person", true, 90)}</Text>
		<Text {...creditsTableProps} column={5} row={2}>{calculateLessonCost("A-Level", null, null, "In-Person", true, 120)}</Text>

		<Text {...creditsTableProps} column={2} row={3}>{calculateLessonCost("University", null, null, "In-Person", true, 45)}</Text>
		<Text {...creditsTableProps} column={3} row={3}>{calculateLessonCost("University", null, null, "In-Person", true, 60)}</Text>
		<Text {...creditsTableProps} column={4} row={3}>{calculateLessonCost("University", null, null, "In-Person", true, 90)}</Text>
		<Text {...creditsTableProps} column={5} row={3}>{calculateLessonCost("University", null, null, "In-Person", true, 120)}</Text>

		<Text {...creditsTableProps} column={2} row={4}>{calculateLessonCost("University", null, null, "Online", true, 45)}</Text>
		<Text {...creditsTableProps} column={3} row={4}>{calculateLessonCost("University", null, null, "Online", true, 60)}</Text>
		<Text {...creditsTableProps} column={4} row={4}>{calculateLessonCost("University", null, null, "Online", true, 90)}</Text>
		<Text {...creditsTableProps} column={5} row={4}>{calculateLessonCost("University", null, null, "Online", true, 120)}</Text>

		<Flex backgroundColor={tableColour} height={"0.2vh"} columnStart={1} columnEnd={6} row={2} />
		<Flex backgroundColor={tableColour} height={"0.2vh"} columnStart={1} columnEnd={6} row={3} />
		<Flex backgroundColor={tableColour} height={"0.2vh"} columnStart={1} columnEnd={6} row={4} />

		<Flex backgroundColor={tableColour} width={"0.2vh"} column={2} rowStart={1} rowEnd={5} />
		<Flex backgroundColor={tableColour} width={"0.2vh"} column={3} rowStart={1} rowEnd={5} />
		<Flex backgroundColor={tableColour} width={"0.2vh"} column={4} rowStart={1} rowEnd={5} />
		<Flex backgroundColor={tableColour} width={"0.2vh"} column={5} rowStart={1} rowEnd={5} />
	</Grid>


	return (
		<Flex direction={"column"} gap={"3vh"} alignItems={"center"}>
			{titleText}
			{currentCreditsText}
			{descriptionFlex}
			{creditInput}
			{checkoutButton}
			{/* {creditsPricing} */}
			{/* {creditsPricingTable} */}
			{lessonCreditsTable}
			{redirect}
		</Flex>
	);
}

export default LessonCredits;